import * as React from "react";
import { Helmet } from "react-helmet";
import Standard from "../components/standard";
import "../styles/index.scss";
import { graphql } from "gatsby";
import Img from "gatsby-image";

const IndexPage = ({ data }) => {
    return (
        <Standard>
            <main>
                <Helmet>
                    <title>Eugen.Iwanow.IT</title>
                    <link rel="icon" href="/" type="image/png"></link>
                </Helmet>
                <h1>Eugen.Iwanow.IT</h1>
                <div className="content">
                    <div>
                        <Img fluid={data.file.childImageSharp.fluid} />
                    </div>
                    <div>
                        <h3>Skills</h3>
                        {/* <Img /> */}
                        <p>
                            HTML, CSS, SCSS, PHP, JavaScript, Python, WordPress,
                            MODx, jQuery, Git, Gulp, React, Gatsby
                        </p>
                        <h3>Moto</h3>
                        <p>Simple = Fast, Simple + Fast = SimpleFast</p>
                        <h3>Credo</h3>
                        <p>Frameworks RULES! Templates SUCKS!</p>
                    </div>
                </div>
            </main>
            <footer>
                <p>Contact</p>
                <p>
                    <a
                        target="_blank"
                        href="https://t.me/eugeniwanow"
                        title="Telegram"
                        rel="noreferrer"
                    >
                        Telegram
                    </a>{" "}
                    -{" "}
                    <a href="mailto:eugen@iwanow.it" title="Mail">
                        Mail
                    </a>
                </p>
            </footer>
        </Standard>
    );
};

export default IndexPage;

export const query = graphql`
    query {
        file(relativePath: { eq: "eugen-iwanow.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
        }
    }
`;
